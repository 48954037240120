import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/database";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDln9GMoUgxs1Ho4EBW2eFmN1lx9CQ_ke4",
  authDomain: "daddy-issues-club.firebaseapp.com",
  projectId: "daddy-issues-club",
  storageBucket: "daddy-issues-club.appspot.com",
  messagingSenderId: "895389370070",
  appId: "1:895389370070:web:c9307080ff9ebf705e2b77",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const database = firebaseApp.database();
export const firestore = firebaseApp.firestore();
export const firebaseAnalytics = firebaseApp.analytics();

export default firebaseApp;

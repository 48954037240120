import "./App.css";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { firestore } from "./firebase";

import Web3 from "web3";
import abi from "./abi.json";
import { useNavigate } from "react-router-dom";

// social media link
// https://www.instagram.com/daddyissuesclub.io/

function App() {
  const router = useNavigate()
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);
  const [mintCheck, setMintCheck] = useState(false);
  const [whitelist, setWhitelist] = useState(false);
  // For whitelist
  const [name1, setName1] = useState("");
  const [email1, setEmail1] = useState("");
  const [wallet, setWallet] = useState("");
  const [id1, setId1] = useState("");
  const [mintCount, setMintCount] = useState(1);

  const handleSubmitWhitelist = async (event) => {
    event.preventDefault();
    await firestore.collection("whitelist").add({
      name: name1,
      email: email1,
      wallet: wallet,
      mintCount: mintCount,
      id: id1,
    });
    setName1("");
    setEmail1("");
    setWallet("");
    setMintCount(1);
    setId1("");
    alert(
      "Your submission will be reviewed by our team and you will be notified. Meanwhile add us on social media platforms for updates."
    );
  };

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);
    const ins = new web3.eth.Contract(
      abi,
      "0x554f70d351659c0c858c2A8586DF87483b92569D"
    );
    setInstance(ins);
    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        console.log(tokenCount);
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("");
      alert("Meta Mask not detected");
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 10) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <div className="">
      {mintCheck ? (
        <div className="mint-page">
          <Container>
            <div className="navb">
              {/* <a href="" target="_blank" rel="noreferrer"> */}
              <div className="">
                <h2 className="text-center font-weight-bold text-uppercase">
                  daddyissuesclub
                </h2>
              </div>

              {/* </a> */}
              <div className=" text-center rmt">
                <div onClick={requestAccount} className="btn cusbtn1">
                  <span className="font-weight-bold text-uppercase">
                    {add ? add.substring(0, 15) : "Connect Wallet"}
                  </span>
                </div>
              </div>
            </div>
          </Container>
          <div className="mintbox pb-5">
            <Container className="d-flex justify-content-center">
              <div className="mint">
                <div className="">
                  <h1 className="mih1 text-center  text-uppercase mb-3">
                    Mint Your NFT's
                  </h1>
                  <p className="subtitle p-0 m-0 text-center  text-uppercase">
                    Max Per Transaction = 10
                  </p>
                  <p className="subtitle p-0 m-0 text-center  text-uppercase">
                    Join the daddyissuesclub Community.
                  </p>
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    <div className="mx-3">
                      <img
                        onClick={minus}
                        className="plus-img"
                        src={require("./assets/img/4.png").default}
                        alt=""
                      />
                    </div>
                    <div className="display-number d-flex justify-content-center align-items-center">
                      <span className="number">{mintNumber}</span>
                    </div>
                    <div className="mx-3">
                      <img
                        onClick={plus}
                        className="plus-img"
                        src={require("./assets/img/2.png").default}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <button
                      onClick={mint}
                      className="mintbutton btn  text-uppercase"
                    >
                      Mint Now
                    </button>
                  </div>
                  <div className="text-center mt-4">
                    <h5 className="font-weight-bold bfont">
                      Mint Price: 0.05 ETH
                    </h5>
                    <h3 className="font-weight-bold bfont">
                      {" "}
                      {totalMinted} / 10600
                    </h3>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="welcome">
            <div className="welcomebox max-w-sm">
              <div className="">
                <h2 className="text-uppercase text-rgb font-weight-bold">
                  Welcome to
                </h2>
                <p
                  style={{ color: "#33ccff" }}
                  className="text-uppercase  font-weight-bold"
                >
                  Access the Lifestyle of your Dreams
                  Change Join whitelist to Members Waitlist Change wanna be metababez to Become a metababe                  </p>

                <img
                  className="img-fluid welcomelogo"
                  src={require("./assets/img/logo.png").default}
                  alt=""
                />
              </div>
              <div
                onClick={() => router("/metababez")}
                className="w-100 font-weight-bold btn btn-lg cusbtn"
              >
                Wanna be our Metababez
              </div>
              {/* <div
                  onClick={whiteList}
                  className="mt-4 font-weight-bold w-100 btn btn-lg cusbtn"
                >
                  Join our Whitelist
                </div> */}
              <div className="mt-4 socilink d-flex">
                <div className="mx-3">
                  <a
                    href="https://discord.gg/5q8NydybcX"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid si"
                      src={require("./assets/img/Discord.png").default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="mx-3 rmt">
                  <a
                    href="https://twitter.com/daddyissuesnft"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid si"
                      src={require("./assets/img/Twitter.png").default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="mx-3 rmt">
                  <a
                    href="https://www.instagram.com/daddyissuesclub.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid si"
                      src={require("./assets/img/Instagram.png").default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="mx-3 rmt">
                  <a
                    href="https://vm.tiktok.com/ZTdG8rwYN/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid si"
                      src={require("./assets/img/tiktok.png").default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="mx-3 rmt">
                  <a
                    className="btn btn-light font-weight-bold text-uppercase"
                    href="https://www.daddyissuesclub.io/"
                    target="_blank"
                    rel="noreferrer"  
                  >
                    Whitepaper
                  </a>
                </div>
              </div>
            </div>
          </div>

          {whitelist ? (
            <div className="meta">
              <div className="pt-4">
                <h1
                  className="text-center font-weight-bold text-uppercase"
                  style={{ color: "#000" }}
                >
                  Whitelist
                </h1>
                <div className="welcomebox">
                  <div className="">
                    <p className="text-uppercase text-warning font-weight-bold">
                      Required fields to join our Whitelist
                    </p>
                  </div>
                  <form action="" onSubmit={(e) => handleSubmitWhitelist(e)}>
                    <div className="form">
                      <div className="form-group">
                        <input
                          value={name1}
                          onChange={(e) => setName1(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter name"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          value={email1}
                          onChange={(e) => setEmail1(e.target.value)}
                          type="email"
                          className="form-control"
                          placeholder="Enter email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          value={wallet}
                          onChange={(e) => setWallet(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Wallet ID that you will mint with for whitelist
                      "
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-label">
                          How many NFT do you want to mint?
                        </label>
                        <input
                          value={mintCount}
                          onChange={(e) => setMintCount(e.target.value)}
                          type="number"
                          min="1"
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <input
                          value={id1}
                          onChange={(e) => setId1(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Enter Social Media handle / ID
                    "
                        />
                      </div>

                      <button
                        type="submit"
                        className="w-100 font-weight-bold btn btn-lg cusbtn"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default App;

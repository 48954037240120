import { useState } from "react";
import { firestore } from "./firebase";
import Select from 'react-select'

const options = [
    {
        "value": "instagram",
        "label": "Instagram"
    },
    {
        "value": "twitter",
        "label": "Twitter"
    },
    {
        "value": "tiktok",
        "label": "Tik Tok"
    },
    {
        "value": "fb",
        "label": "FaceBook"
    }
]


const MetaBabex = (props) => {
    // For metababez
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [platform, setPlatform] = useState([]);
    const [followers, setFollowers] = useState({});
    const [id, setId] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [knowledge, setKnowledge] = useState("yes");
    const [eligible, setEligible] = useState(true);


    const handleFollowersChange = (platformName, value) => {
        setFollowers(prev => ({
            ...prev,
            [platformName]: value
        }));
    };

    const handleIdChange = (platformName, value) => {
        setId(prev => ({
            ...prev,
            [platformName]: value
        }));
    };

    const handleSubmitMetababez = async (event) => {
        event.preventDefault();
        try {
            await firestore.collection("metababez").add({
                name: name,
                eligible: eligible,
                email: email,
                platform: platform,
                followers: followers,
                id: id,
                country: country,
                state: state,
                city: city,
                knowledge: knowledge,
            });
            setName("");
            setEmail("");
            setFollowers(null);
            setId("");
            setCountry("");
            setState("");
            setCity("");
            // toast.success("Your submission will be reviewed by our team and you will be notified. Meanwhile add us on social media platforms for updates.");
        }
        catch (err) {
            console.log(err)
            if (err instanceof Error) {
                // toast.error(err.message)
            }
            // toast.error("Something Went Wrong")
        }
        finally {

        }
    };

    return (
        <div className="meta">
            <div className="pt-4">
                <h1
                    className="text-center font-weight-bold text-dark text-uppercase"
                >
                    metababez
                </h1>
                <div className="welcomebox" style={{ maxWidth: "450px", width: "100%" }}>
                    <div className="">
                        <p className="text-uppercase text-warning font-weight-bold">
                            Required fields to be our Metababez
                        </p>
                    </div>
                    <form action="" onSubmit={(e) => handleSubmitMetababez(e)}>
                        <div className="form">
                            <div className="form-group">
                                <input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Are you 18+?</label>
                                <select
                                    onChange={(e) => {
                                        if (e.target.value === "1") {
                                            setEligible(true);
                                        } else {
                                            setEligible(false);
                                        }
                                    }}
                                    className="form-select form-control"
                                    aria-label="Default select example"
                                >
                                    <option value="1" defaultValue>
                                        Yes
                                    </option>
                                    <option value="2">No</option>
                                </select>
                            </div>
                            {eligible ? (
                                <div className="">
                                    <div className="form-group">
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter email"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">
                                            Maximum reach of followers on one of the platform
                                        </label>

                                        <Select
                                            onChange={(hello) => setPlatform(hello.map(el => el.label))}
                                            isMulti
                                            options={options}
                                            classNames={{
                                                option: () => "option"
                                            }}
                                            styles={{
                                                control: (baseStyles, state) => {
                                                    return {
                                                        ...baseStyles,
                                                        backgroundColor: "transparent"
                                                    }
                                                },
                                                placeholder: (baseStyles, state) => {
                                                    return {
                                                        ...baseStyles,
                                                        color: "white"
                                                    }
                                                },
                                                menu: (baseStyles, state) => {
                                                    return {
                                                        ...baseStyles,
                                                        backgroundColor: "rgba(0,0,0,0.5)",
                                                        backdropFilter: "blur(10px)"
                                                    }
                                                },
                                                option: (baseStyles, state) => {
                                                    return {
                                                        ...baseStyles,
                                                        backdropFilter: "blur(10px)"
                                                    }
                                                }
                                            }}
                                        />

                                        {/* <select
                                            onChange={(e) => {
                                                setPlatform(e.target.value);
                                            }}
                                            className="form-select form-control form-multi-select"
                                            aria-label="Default select example"
                                            multiple
                                        >
                                            <option value="instagram" defaultValue>
                                                Instagram
                                            </option>
                                            <option value="twitter">Twitter</option>
                                            <option value="tiktok">Tik Tok</option>
                                            <option value="fb">FaceBook</option>
                                        </select> */}
                                    </div>
                                    {platform.map((platformName) => (
                                        <div key={platformName}>
                                            <div className="form-group">
                                                <label className="form-label">Number of followers on {platformName}</label>
                                                <input
                                                    value={followers?.[platformName] || ""}
                                                    onChange={(e) => handleFollowersChange(platformName, e.target.value)}
                                                    type="number"
                                                    min="1"
                                                    className="form-control"
                                                    placeholder="Enter Number of followers"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    value={id?.[platformName] || ""}
                                                    onChange={(e) => handleIdChange(platformName, e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={`Enter ${platformName} handle / ID`}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="form-group">
                                        <input
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            placeholder="Country"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            placeholder="State"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            placeholder="City"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">
                                            Knowledge of Digital assets (Crypto, NFT,
                                            Metaverse)
                                        </label>

                                        <select
                                            onChange={(e) => {
                                                setKnowledge(e.target.value);
                                            }}
                                            className="form-select form-control"
                                            aria-label="Default select example"
                                        >
                                            <option value="yes" defaultValue>
                                                Yes
                                            </option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-100 font-weight-bold btn btn-lg cusbtn"
                                    >
                                        Submit
                                    </button>
                                </div>
                            ) : (
                                <p className="text-warning">You are not eligible </p>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default MetaBabex;